import React, {useState, useEffect} from 'react';
import {ReactComponent as LogoSVG} from "./svg/logo.svg";
import { Transition } from 'react-transition-group';
import 'animate.css';

import './styles/Logo.scss';

export default function Logo(props) {
    const [display, setDisplay] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setDisplay(false);
        }, 2000);
    });

    return (
    <Transition in={display} timeout={3000}>
        {state => {
            if(state === 'exited' && typeof props.onExited === 'function'){
                props.onExited(2000);
            }
            return <LogoSVG alt="Logo" className={"App-logo " + state}/>
        }}
    </Transition>
    );

}
