import React, {useState, useEffect} from 'react';
import { Transition } from 'react-transition-group';
import 'animate.css';
import './styles/ChatMessage.scss';
import {ReactComponent as WritingAnim} from './svg/writing.svg';

export default function ChatMessage(props) {
    return (
        <div className={"chat-message " + (props.from === "agent" ? "agent" : "user")} style={props.style}>
            {props.children}
        </div>
    );
}
