import React, {useState, useEffect} from 'react';
import 'animate.css';
import './styles/BottomBar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

export default function BottomBar(props) {

    let defaultText = "Type a message...";

    //function that remove placeholder from input when user click on it
    function removePlaceholder(e){
        e.target.placeholder = "";
    }

    //function that add placeholder from empty input when user click outside of it
    function addPlaceholder(e){
        if(e.target.value === "") {
            e.target.placeholder = defaultText;
        }
    }

    function onSend(e) {
        e.preventDefault();
        const msg = {
            from: 'user',
            text: e.target.message.value
        };
        props.onSend(msg);
        e.target.message.value = "";
    }

    return (
        <div className="bottom-bar">
            <div className="chat">
                <form onSubmit={e => onSend(e)}>
                <input id="message" type="text" placeholder={defaultText} onClick={removePlaceholder} onKeyUp={addPlaceholder} onBlur={addPlaceholder} autoComplete={"off"}/>
                <button type="submit"><FontAwesomeIcon icon={faPaperPlane}/></button>
                </form>
            </div>
        </div>
    );

}
