import './App.scss';
import Logo from './components/Logo';
import ChatBox from './components/ChatBox';
import React,{useState} from "react";

function App() {

    const [chatBoxDisplayed, setChatBoxDisplayed] = useState(false);

    function displayChatBox(endTime) {
        setTimeout(function(){
            setChatBoxDisplayed(true);
        }, endTime);
    }

    return (
        <div className="App">
          <header className="App-header">
            <Logo onExited={displayChatBox}/>
            <ChatBox display={chatBoxDisplayed}/>
          </header>
        </div>
    );
}

export default App;
