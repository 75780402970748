import React, {useState, useEffect} from 'react';
import useSound from 'use-sound';
import { Transition } from 'react-transition-group';
import 'animate.css';
import BottomBar from './BottomBar';
import ChatMessage from './ChatMessage';
import ChatAgentTyping from './ChatAgentTyping';
import './styles/ChatBox.scss';

export default function ChatBox(props) {

    const [messages, setMessages] = useState([]);
    const [agentIsTyping, setAgentIsTyping] = useState(true);
    const [playNewMsg] = useSound(
        './sound/new_message.mp3',
        { volume: 0.5 }
    );

    function setNewMessage(msg) {
        playNewMsg();
        setMessages([
            ...messages,
            msg
        ]);
        msg.from === 'agent' && setAgentIsTyping(false);
    }

    function newAgentMessage(msg) {
        setAgentIsTyping(true);
        setTimeout(() => {
            setNewMessage(msg);
        }, 1000);
    }

    function sendMessage(msg) {
        setNewMessage(msg);
    }

    function startConversation() {
        const timer = setTimeout(() => {
            newAgentMessage({
                from: 'agent',
                text: 'Hello, how can I help you?'
            });
        }, 1000);
        return () => clearTimeout(timer);
    }

    return (
        <Transition in={props.display} timeout={2000} >
            {state => {
                if(state === 'entered' && messages.length === 0){
                    startConversation();
                }
                return (
                <div className={"chat-box " + state}>
                    <div className="chat-box-content">
                        {messages.map((msg, index) => {
                            return (
                                <ChatMessage key={index} from={msg.from} style={{top: index * 70}}>{msg.text}</ChatMessage>
                            )
                        })}
                        {agentIsTyping && <ChatAgentTyping />}
                    </div>
                    <BottomBar onSend={e => sendMessage(e)}/>
                </div>
            )}}
        </Transition>
    );

}
